import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  SearchForm,
  SearchBar,
  SearchButton,
  CloseIcon,
  LargeSearchForm,
  SearchBarWrapper,
  LargeSearchBar,
  AutocompleteText,
  ClearButton,
  LargeSearchButton
} from "./GlobalSearchBarStyled";
import SearchIcon from "./SearchIcon";
import { useBreakpoint } from "../Breakpoints";
import { useSearch } from "../../contexts/SearchContext";
import { useSearchAutocomplete, useActiveSearch } from "../../hooks";
import useDebounce from "../../hooks/useDebounce";
import useUser from "../../hooks/useUser";

const GlobalSearchBar = ({
  setShowResults,
  placeholderText = "",
  flyoutType = "Small"
}) => {
  const breakpoints = useBreakpoint();
  const isScreenWidth = breakpoints["ScreenWidth"];
  const defaultValue = "";
  const [isSubmit, setIsSubmit] = useState(false);
  const isLargeFlyout = flyoutType === "Large";
  const {
    searchValue,
    searchBarValue,
    setSearchValue,
    setShowSearchBar,
    showSearchBar,
    setSearchBarValue,
    setSearchBarChanged,
    setLoadingRedirection,
    setAutocompleteResults,
    setActiveSearch,
    autocompleteString,
    setAutocompleteString,
    autocompleteText,
    setAutocompleteText
  } = useSearch();
  const inputElement = useRef(null);
  const { searchAutocomplete, fetchAutocompleteResults } =
    useSearchAutocomplete();
  const { activeSearchResults, fetchActiveSearchResults } = useActiveSearch();
  const debouncedSearchTerm = useDebounce(searchBarValue, 500);
  const { user: { data: { isDSO } = {} } = {} } = useUser();

  useEffect(() => {
    if (searchBarValue.length < 3) {
      setAutocompleteString("");
      setAutocompleteText("");
    } else {
      fetchAutocompleteResults({ phrase: searchBarValue, showRetailProducts: isDSO });
    }
  }, [searchBarValue]);

  useEffect(() => {
    if (!isLargeFlyout || searchBarValue.length < 3) return;
    if (debouncedSearchTerm) {
      fetchActiveSearchResults({ phrase: searchBarValue, showRetailProducts: isDSO });
    }
  }, [debouncedSearchTerm]);

  const setTypeAheadString = (suggestions) => {
    let typeAheadText = "";
    const searchValueLower = searchBarValue.toLowerCase();
    suggestions.forEach((suggestion) => {
      const value = suggestion.value.toLowerCase();
      if (
        value.startsWith(searchValueLower) &&
        (!typeAheadText || typeAheadText.length > value.length)
      ) {
        typeAheadText = value;
      }
    });
    if (typeAheadText.includes(searchValueLower)) {
      const typeAheadString =
        typeAheadText && typeAheadText.replace(searchValueLower, "");
      typeAheadString && setAutocompleteString(typeAheadString);
      typeAheadString && setAutocompleteText(searchBarValue + typeAheadString);
    } else {
      setAutocompleteString("");
      setAutocompleteText("");
    }
  };

  useEffect(() => {
    const suggestions = searchAutocomplete?.["Search Suggestions"] || [];
    setAutocompleteResults(searchAutocomplete);
    searchBarValue &&
      suggestions?.length >= 1 &&
      setTypeAheadString(suggestions);
  }, [searchAutocomplete, searchBarValue]);

  useEffect(() => {
    setActiveSearch(activeSearchResults);
  }, [activeSearchResults]);

  useEffect(() => {
    if (inputElement.current && showSearchBar) {
      inputElement.current.focus();
    }
  }, [searchBarValue, showSearchBar]);

  useEffect(() => {
    setShowResults(searchBarValue.length > 2);
  }, [searchBarValue]);

  useEffect(() => {
    isSubmit && handleRedirection();
  }, [isSubmit]);

  useEffect(() => {
    if (searchBarValue) return;
    const isMatch = window.location.href.indexOf("/search") > -1;
    isMatch && setInitialValue();
  }, [searchValue]);

  const handleClose = () => {
    setSearchBarChanged(false);
    setShowSearchBar(false);
    setSearchBarValue(defaultValue);
    setAutocompleteText("");
    setAutocompleteString("");
  };

  const handleValueChange = (e) => {
    const newValue = e.target.value;
    setSearchBarChanged(true);
    setSearchBarValue(newValue);
    if (newValue.length < 3) {
      setAutocompleteText("");
      setAutocompleteString("");
    }
  };

  const handleRedirection = () => {
    searchValue !== defaultValue
      ? (window.location.href = `/search?q=${searchValue}`)
      : (window.location.href = "/");
    handleClose();
  };

  const setInitialValue = () => {
    searchValue && setSearchBarValue(searchValue);
  };

  const handleClear = () => {
    setSearchBarChanged(true);
    setSearchBarValue("");
    setAutocompleteText("");
    setAutocompleteString("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sessionStorage?.removeItem("searchPrevNetsuiteId");
    sessionStorage?.removeItem("searchPageScroll");
    setLoadingRedirection(true);
    setSearchValue(searchBarValue);
    setIsSubmit(true);
  };

  const handleKeyDown = (e) => {
    if (e.code === "ArrowRight") {
      setSearchBarChanged(true);
      setSearchBarValue(searchBarValue + autocompleteString);
      setAutocompleteString("");
      setAutocompleteText("");
    }
    if (e.keyCode === 13) {
      setLoadingRedirection(true);
      setSearchValue(searchBarValue);
      setIsSubmit(true);
    }
  };

  const tabIndexValue = showSearchBar ? 0 : -1;

  const showClearButton = searchBarValue.length > 0;

  return isLargeFlyout ? (
    <LargeSearchForm onSubmit={(e) => handleSubmit(e)}>
      <SearchBarWrapper>
        <LargeSearchBar
          aria-label="Tab forward for search suggestions, those will be displayed below"
          value={searchBarValue}
          onChange={(e) => handleValueChange(e)}
          type="text"
          ref={inputElement}
          tabIndex={tabIndexValue}
          placeholder={placeholderText}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        <AutocompleteText
          tabIndex={-1}
          type="text"
          defaultValue={autocompleteText}
        />
      </SearchBarWrapper>
      <LargeSearchButton
        aria-label="Search"
        tabIndex={tabIndexValue}
      ></LargeSearchButton>
      {showClearButton && (
        <ClearButton
          aria-label="Clear search bar"
          type="button"
          tabIndex={tabIndexValue}
          onClick={() => handleClear()}
        >
          Clear
        </ClearButton>
      )}
    </LargeSearchForm>
  ) : (
    <SearchForm onSubmit={(e) => handleSubmit(e)}>
      <SearchBar
        aria-label="Tab forward for search suggestions, those will be displayed below"
        value={searchBarValue}
        onChange={(e) => handleValueChange(e)}
        type="text"
        ref={inputElement}
        tabIndex={tabIndexValue}
        placeholder={placeholderText}
      />
      <SearchButton aria-label="Search" tabIndex={tabIndexValue}>
        {isScreenWidth && <SearchIcon />}
      </SearchButton>
      <CloseIcon
        aria-label="Close search bar"
        type="button"
        tabIndex={tabIndexValue}
        onClick={() => handleClose()}
      />
    </SearchForm>
  );
};

export default GlobalSearchBar;

GlobalSearchBar.propTypes = {
  setShowResults: PropTypes.func,
  placeholderText: PropTypes.string,
  flyoutType: PropTypes.string
};

import { Typography } from "@serenaandlily/constants/constants";

const defaultTheme = {
  heading: {
    themeName: "text",
    overrides: {
      fontFamily: Typography.BeatriceMedium,
      fontSize: 20,
      fontSizeTablet: 20,
      fontSizeDesktop: 20,
      lineHeight: 1.1,
      fontColor: "Midnight"
    }
  },
  subheading: {
    themeName: "textSmall",
    fontWeight: 600,
    fontFamily: "Beatrice",
    lineHeight: 2
  },
  content: {
    fontFamily: Typography.BeatriceRegular,
    fontSize: 14,
    fontSizeTablet: 14,
    fontSizeDesktop: 14,
    lineHeight: 1.2,
    fontColor: "Midnight",
    letterSpacing: "0.14px"
  },
  continueShopping: {
    fontFamily: Typography.BeatriceLight,
    fontSize: 14,
    fontSizeTablet: 14,
    fontSizeDesktop: 14,
    lineHeight: 1.2,
    fontColor: "Midnight",
    letterSpacing: "0.14px"
  }
};

export default defaultTheme;
